import { getStatusForId as getStatus } from '@ms/yammer-data-support';

import { DismissablePrompt, DismissablePromptKey, EntityStatus, YammerState } from '../../state/types';
import { createFindById } from '../findById';

export const findById = createFindById('dismissablePrompts');

type GetStatusForId = (state: YammerState, id: DismissablePrompt['id']) => EntityStatus;
export const getStatusForId: GetStatusForId = (state, id) => getStatus(state.dismissablePrompts, id);

type FindFirstUndismissedPrompt = (
  state: YammerState,
  keys: DismissablePromptKey[]
) => DismissablePromptKey | undefined;
export const findFirstUndismissedPromptKey: FindFirstUndismissedPrompt = (state, keys) =>
  keys.find((key) => {
    const prompt = findById(state, key);

    return prompt && !prompt.isDismissed;
  });

type IsPromptDismissed = (state: YammerState, id: DismissablePrompt['id']) => boolean;
export const isPromptDismissed: IsPromptDismissed = (state, id) => {
  const prompt = findById(state, id);

  return (prompt && prompt.isDismissed) || false;
};

export const getCurrentlyVisiblePromptKey = (state: YammerState): DismissablePromptKey | null =>
  state.dismissablePromptView.currentlyVisiblePromptKey;

type HasCurrentlyVisiblePromptKey = (state: YammerState) => boolean;
export const hasCurrentlyVisiblePromptKey: HasCurrentlyVisiblePromptKey = (state) =>
  !!state.dismissablePromptView.currentlyVisiblePromptKey;

type IsDataLoaded = (state: YammerState, id: DismissablePrompt['id']) => boolean;
export const isDataLoaded: IsDataLoaded = (state: YammerState, id: DismissablePrompt['id']) =>
  getStatusForId(state, id) === 'fulfilled';

type IsCurrentlyVisiblePromptKey = (state: YammerState, key: DismissablePrompt['id']) => boolean;
export const isCurrentlyVisiblePromptKey: IsCurrentlyVisiblePromptKey = (
  state: YammerState,
  key: DismissablePromptKey
) => {
  const currentPromptKey = getCurrentlyVisiblePromptKey(state);
  const areAllOtherPromptsDismissed = currentPromptKey == null;
  const prompt = findById(state, key);
  const isRequestLoaded = isDataLoaded(state, key);
  const isCurrentPromptKeyVisible = !!currentPromptKey && currentPromptKey === prompt?.id;

  return isRequestLoaded && (areAllOtherPromptsDismissed || isCurrentPromptKeyVisible);
};

type ShouldDisplayTeachingBubble = (state: YammerState, id: DismissablePrompt['id'], isEnabled: boolean) => boolean;
export const shouldDisplayTeachingBubble: ShouldDisplayTeachingBubble = (state, id, isEnabled) => {
  const promptIsDismissed = isPromptDismissed(state, id);
  const isTheCurrentKey = isCurrentlyVisiblePromptKey(state, id);

  return !promptIsDismissed && isTheCurrentKey && isEnabled;
};
