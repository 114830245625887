import {
  getAnalyticsSettings,
  getSelectedGroupId,
  getSelectedNetworkActivitySettings,
  getSelectedOnBehalfOfUserId,
} from '@ms/yammer-data/dist/domains/analyticsSettings/selectors';

import { useSelector } from '../useSelector';

type UseSelectedOnBehalfOfUserId = () => ReturnType<typeof getSelectedOnBehalfOfUserId>;
export const useSelectedOnBehalfOfUserId: UseSelectedOnBehalfOfUserId = () => useSelector(getSelectedOnBehalfOfUserId);

type UseSelectedNetworkActivitySettings = () => ReturnType<typeof getSelectedNetworkActivitySettings>;
export const useSelectedNetworkActivitySettings: UseSelectedNetworkActivitySettings = () =>
  useSelector(getSelectedNetworkActivitySettings);

type UseAnalyticsSettings = () => ReturnType<typeof getAnalyticsSettings>;
export const useAnalyticsSettings: UseAnalyticsSettings = () => useSelector(getAnalyticsSettings);

type UseSelectedGroupId = () => ReturnType<typeof getSelectedGroupId>;
export const useSelectedGroupId: UseSelectedGroupId = () => useSelector(getSelectedGroupId);

