import { getStatusForId as getStatus } from '@ms/yammer-data-support';

import { DismissablePromptTypeEntity, EntityStatus, YammerState } from '../../state/types';
import { createFindById } from '../findById';

type GetStatusForId = (state: YammerState, id: DismissablePromptTypeEntity['id']) => EntityStatus;
export const getStatusForId: GetStatusForId = (state, id) => getStatus(state.dismissablePromptsByType, id);

export const findById = createFindById('dismissablePromptsByType');

type IsPromptDismissed = (state: YammerState, id: DismissablePromptTypeEntity['id']) => boolean;
export const isPromptDismissed: IsPromptDismissed = (state, id) => {
  const prompt = findById(state, id);

  return !!prompt?.isDismissed;
};

type IsDataLoaded = (state: YammerState, id: DismissablePromptTypeEntity['id']) => boolean;
export const isDataLoaded: IsDataLoaded = (state, id) => getStatusForId(state, id) === 'fulfilled';
