import { GlobalCopilotNavButton } from '@ms/yammer-copilot/dist/integration';
import { AppNavigation } from '@ms/yammer-features-app-navigation';
import { CreateCampaignClickedEntrypointEnum } from '@ms/yammer-hooks/dist/campaignCreation';
import { useAndReportExperimentTreatment } from '@ms/yammer-hooks/dist/experiments';
import { UserProfileMenuButton } from '@ms/yammer-left-rail';
import { UniversalCreateButtonProvider } from '@ms/yammer-universal-create-button';
import { useCampaignCreateModal } from '@ms/yammer-web-components/dist/campaignCreateEdit/CampaignCreateModal/hooks/useCampaignCreateModal';
import { useGroupCreateModal } from '@ms/yammer-web-components/dist/groupCreateEdit/LoadableGroupCreateModal';
import NavigationCommunities from '@ms/yammer-web-components/dist/navigation/NavigationCommunities';
import { usePostCreateModal } from '@ms/yammer-web-components/dist/publisher/LoadableUniversalCreatePublisherModal';
import React, { ReactNode, useCallback } from 'react';

import { useHomeLinkActionButtons } from './useHomeLinkActionButtons';

type UseGetNavigationCallback = () => () => ReactNode;
export const useGetNavigationCallback: UseGetNavigationCallback = () => {
  const homeLinkActionButtons = useHomeLinkActionButtons();
  const isGlobalCopilotEnabled = useAndReportExperimentTreatment('VivaEngageGlobalCopilot');

  const campaignCreateModal = useCampaignCreateModal(CreateCampaignClickedEntrypointEnum.UniversalCreateButton);

  return useCallback(
    () => (
      <UniversalCreateButtonProvider
        useGroupCreateModal={useGroupCreateModal}
        campaignCreateModal={campaignCreateModal}
        usePostCreateModal={usePostCreateModal}
      >
        <AppNavigation
          communitiesNavLinksContent={<NavigationCommunities />}
          userProfileLinkActionButtons={[
            ...(isGlobalCopilotEnabled ? [<GlobalCopilotNavButton key="globalCopilotNavButton" />] : []),
            <UserProfileMenuButton key="userProfileMenuButton" />,
          ]}
          homeLinkActionButtons={homeLinkActionButtons}
        />
      </UniversalCreateButtonProvider>
    ),
    [campaignCreateModal, homeLinkActionButtons, isGlobalCopilotEnabled]
  );
};
