import { User } from '@ms/yammer-data/dist/state/types';

import { GetBaseUrlProps, getBaseUrl } from '../location';

export const userSortByKey = 'sortBy';
export const userAchievementTypeQueryKey = 'type';
export const userAchievementLevelQueryKey = 'level';
export const userAchievementPathQueryKey = 'path';
export const userAchievementCountQueryKey = 'count';

type UserPage = (userId: User['id']) => string;
export const userPageBasePath = '/users';
export const userPage: UserPage = (userId) => `${userPageBasePath}/${userId}`;
export const userStorylinePage: UserPage = (userId) => `${userPageBasePath}/${userId}/storyline`;
export const userAudienceFeedPage: UserPage = (userId) => `${userPageBasePath}/${userId}/audiencefeed`;
export const userMyBookmarksPage: UserPage = (userId) => `${userPageBasePath}/${userId}/bookmarks`;
export const userPendingPostsPage: UserPage = (userId) => `${userPageBasePath}/${userId}/pending-posts`;
export const userMyDraftsPage: UserPage = (userId) => `${userPageBasePath}/${userId}/pending-posts/drafts`;
export const userScheduledPostsPage: UserPage = (userId) => `${userPageBasePath}/${userId}/pending-posts/scheduled`;

type UserPageUrl = (userId: User['id'], host: GetBaseUrlProps['host']) => string;
export const userPageUrl: UserPageUrl = (userId, host) => {
  const baseUrl = getBaseUrl({ host });

  return `${baseUrl}${userPage(userId)}`;
};

type UserStorylinePageUrl = (userId: User['id'], host: GetBaseUrlProps['host']) => string;
export const userStorylinePageUrl: UserStorylinePageUrl = (userId, host) => {
  const baseUrl = getBaseUrl({ host });

  return `${baseUrl}${userStorylinePage(userId)}`;
};

type UserMyBookmarksPageUrl = (userId: User['id'], host: GetBaseUrlProps['host']) => string;
export const userMyBookmarksPageUrl: UserMyBookmarksPageUrl = (userId, host) => {
  const baseUrl = getBaseUrl({ host });

  return `${baseUrl}${userMyBookmarksPage(userId)}`;
};

export enum UserPendingPostsType {
  DRAFTS = 'Drafts',
  SCHEDULED = 'Scheduled',
}

type UserPendingPostsPageUrl = (userId: User['id'], host: GetBaseUrlProps['host']) => string;
export const userPendingPostsPageUrl: UserPendingPostsPageUrl = (userId, host) => {
  const baseUrl = getBaseUrl({ host });

  return `${baseUrl}${userPendingPostsPage(userId)}`;
};
type UserMyDraftsPageUrl = (userId: User['id'], host: GetBaseUrlProps['host']) => string;
export const userMyDraftsPageUrl: UserMyDraftsPageUrl = (userId, host) => {
  const baseUrl = getBaseUrl({ host });

  return `${baseUrl}${userMyDraftsPage(userId)}`;
};

type UserScheduledPostsPageUrl = (userId: User['id'], host: GetBaseUrlProps['host']) => string;
export const userScheduledPostsPageUrl: UserScheduledPostsPageUrl = (userId, host) => {
  const baseUrl = getBaseUrl({ host });

  return `${baseUrl}${userScheduledPostsPage(userId)}`;
};
