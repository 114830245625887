import {
  canManageOnBehalfOfUser,
  canPostOnBehalfOfUser,
  canViewerManageTeam,
  canViewerPostStorylineAnnouncementForLeader,
  canViewerPostToStoryline,
  getAllDelegationTeamLeadersForCurrentUser,
  getCurrentDelegationTeams,
  getCurrentDelegationTeamsStatus,
  getDelegationManagerUsersForCurrentUser,
  getDelegationTeamUsersForCurrentUser,
  getDelegationTeamWithHighestAudienceLeader,
  getOtherDelegationTeams,
  getOwnDelegationTeam,
  hasNetworkWideAudienceForLeader,
} from '@ms/yammer-data/dist/domains/delegation/selectors';
import { getExperimentTreatment } from '@ms/yammer-data/dist/domains/experiments/selectors';
import {
  getSendersToReplyThreadInGroup,
  getSendersToStartThreadInGroup,
} from '@ms/yammer-data/dist/domains/groupPublisherSettings/selectors';
import { getGroupId } from '@ms/yammer-data/dist/domains/publishers/getGroupId';
import { isReply } from '@ms/yammer-data/dist/domains/publishers/selectors';
import { Publisher, User } from '@ms/yammer-data/dist/state/types';

import { useSelector } from '../useSelector';
type UseCurrentDelegationTeamsStatus = () => ReturnType<typeof getCurrentDelegationTeamsStatus>;
export const useCurrentDelegationTeamsStatus: UseCurrentDelegationTeamsStatus = () =>
  useSelector(getCurrentDelegationTeamsStatus);

type UseCurrentDelegationTeams = () => ReturnType<typeof getCurrentDelegationTeams>;
export const useCurrentDelegationTeams: UseCurrentDelegationTeams = () => useSelector(getCurrentDelegationTeams);

type UseOwnDelegationTeam = (userId: User['id']) => ReturnType<typeof getOwnDelegationTeam>;
export const useOwnDelegationTeam: UseOwnDelegationTeam = (userId) =>
  useSelector((state) => getOwnDelegationTeam(state, userId));

type UseOtherDelegationTeams = (userId: User['id']) => ReturnType<typeof getOtherDelegationTeams>;
export const useOtherDelegationTeams: UseOtherDelegationTeams = (userId) =>
  useSelector((state) => getOtherDelegationTeams(state, userId));

type UseDelegationTeamUsersForCurrentUser = () => ReturnType<typeof getDelegationTeamUsersForCurrentUser>;
export const useDelegationTeamUsersForCurrentUser: UseDelegationTeamUsersForCurrentUser = () =>
  useSelector((state) => getDelegationTeamUsersForCurrentUser(state));

type UseDelegationTeamUsersForHomePublisher = (
  publisherId?: Publisher['id']
) => ReturnType<typeof getDelegationTeamUsersForCurrentUser | typeof getSendersToStartThreadInGroup>;
export const useDelegationTeamUsersForHomePublisher: UseDelegationTeamUsersForHomePublisher = (publisherId) =>
  useSelector((state) => {
    const isPostOnBehalfOfCommunityHomePublisherEnabled =
      getExperimentTreatment(state, 'PostOnBehalfOfCommunityHomePublisher') === true;

    if (publisherId && isPostOnBehalfOfCommunityHomePublisherEnabled) {
      const groupId = getGroupId(state, publisherId);
      if (groupId) {
        return getSendersToStartThreadInGroup(state, groupId);
      }
    }

    return getDelegationTeamUsersForCurrentUser(state);
  });

type UseDelegationTeamUsersForPublisher = (
  publisherId?: Publisher['id']
) => ReturnType<
  | typeof getDelegationTeamUsersForCurrentUser
  | typeof getSendersToStartThreadInGroup
  | typeof getSendersToReplyThreadInGroup
>;
export const useDelegationTeamUsersForPublisher: UseDelegationTeamUsersForPublisher = (publisherId) =>
  useSelector((state) => {
    if (publisherId) {
      const groupId = getGroupId(state, publisherId);
      const isReplyPublisher = isReply(state, publisherId);

      if (groupId) {
        return isReplyPublisher
          ? getSendersToReplyThreadInGroup(state, groupId)
          : getSendersToStartThreadInGroup(state, groupId);
      }
    }

    return getDelegationTeamUsersForCurrentUser(state);
  });

type UseAllDelegationTeamLeadersForCurrentUser = () => ReturnType<typeof getAllDelegationTeamLeadersForCurrentUser>;
export const useAllDelegationTeamLeadersForCurrentUser: UseAllDelegationTeamLeadersForCurrentUser = () =>
  useSelector(getAllDelegationTeamLeadersForCurrentUser);

type UseDelegationManagerUsersForCurrentUser = () => ReturnType<typeof getDelegationManagerUsersForCurrentUser>;
export const useDelegationManagerUsersForCurrentUser: UseDelegationManagerUsersForCurrentUser = () =>
  useSelector((state) => getDelegationManagerUsersForCurrentUser(state));

type UseDelegationTeamWithHighestAudienceLeader = () => ReturnType<typeof getDelegationTeamWithHighestAudienceLeader>;
export const useDelegationTeamWithHighestAudienceLeader: UseDelegationTeamWithHighestAudienceLeader = () =>
  useSelector(getDelegationTeamWithHighestAudienceLeader);

type UseCanPostOnBehalfOfUser = (onBehalfOfUserId: User['id'] | undefined) => ReturnType<typeof canPostOnBehalfOfUser>;
export const useCanPostOnBehalfOfUser: UseCanPostOnBehalfOfUser = (onBehalfOfUserId) =>
  useSelector((state) => (onBehalfOfUserId ? canPostOnBehalfOfUser(state, onBehalfOfUserId) : false));

type UseCanManageOnBehalfOfUser = (
  onBehalfOfUserId: User['id'] | undefined
) => ReturnType<typeof canManageOnBehalfOfUser>;
export const useCanManageOnBehalfOfUser: UseCanManageOnBehalfOfUser = (onBehalfOfUserId) =>
  useSelector((state) => canManageOnBehalfOfUser(state, onBehalfOfUserId));

type UseViewerCanPostToStoryline = (leaderId: User['id'] | undefined) => ReturnType<typeof canViewerPostToStoryline>;
export const useViewerCanPostToStoryline: UseViewerCanPostToStoryline = (leaderId) =>
  useSelector((state) => canViewerPostToStoryline(state, leaderId));

type UseViewerCanManageTeam = (leaderId: User['id']) => ReturnType<typeof canViewerManageTeam>;
export const useViewerCanManageTeam: UseViewerCanManageTeam = (leaderId) =>
  useSelector((state) => canViewerManageTeam(state, leaderId));

type UseViewerCanPostStorylineAnnouncementForLeader = (
  leaderId: User['id'] | undefined
) => ReturnType<typeof canViewerPostStorylineAnnouncementForLeader>;
export const useViewerCanPostStorylineAnnouncementForLeader: UseViewerCanPostStorylineAnnouncementForLeader = (
  leaderId
) => useSelector((state) => canViewerPostStorylineAnnouncementForLeader(state, leaderId));

type UseViewerHasNetworkWideAudienceForLeader = (
  leaderId: User['id'] | undefined
) => ReturnType<typeof hasNetworkWideAudienceForLeader>;
export const useViewerHasNetworkWideAudienceForLeader: UseViewerHasNetworkWideAudienceForLeader = (leaderId) =>
  useSelector((state) => hasNetworkWideAudienceForLeader(state, leaderId));
