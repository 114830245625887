import { createContext, useContext } from 'react';

enum Scenarios {
  DisableLinks = 'disableLinks',
  DisableLivePersonaCard = 'disableLivePersonaCard',
  DisableUserContentLinks = 'disableUserContentLinks',
  DisableReplyLinks = 'disableReplyLinks',
  DisableThreadLinks = 'disableThreadLinks',
  IsThreadStarterCopyButtonLinkExternal = 'isThreadStarterCopyButtonLinkExternal',
  ShouldHideDownloadAttachmentLink = 'shouldHideDownloadAttachmentLink',
  ShouldHideLegacyPageLinks = 'shouldHideLegacyPageLinks',
  ShouldHideDescriptionMoreInfoLink = 'shouldHideDescriptionMoreInfoLink',
  ShouldOpenArticleViewLinkInNewWindow = 'shouldOpenArticleViewLinkInNewWindow',
  ShouldOpenArticlePublisherLinkInNewWindow = 'shouldOpenArticlePublisherLinkInNewWindow',
  ShouldOpenAskMeAnythingLinkInNewWindow = 'shouldOpenAskMeAnythingLinkInNewWindow',
  ShouldOpenDiscoverCommunitiesLinkInNewWindow = 'shouldOpenDiscoverCommunitiesLinkInNewWindow',
  ShouldOpenEventLinkInNewWindow = 'shouldOpenEventLinkInNewWindow',
  ShouldOpenGroupFilesInNewWindow = 'shouldOpenGroupFilesInNewWindow',
  ShouldOpenGroupFilesInLegacyPage = 'shouldOpenGroupFilesInLegacyPage',
  ShouldOpenGroupHeaderSettingsLinkInNewWindow = 'shouldOpenGroupHeaderSettingsLinkInNewWindow',
  ShouldOpenGroupInfoLinkInNewWindow = 'shouldOpenGroupInfoLinkInNewWindow',
  ShouldOpenGroupDeleteInNewWindow = 'shouldOpenGroupDeleteInNewWindow',
  ShouldOpenGroupLinkInNewWindow = 'shouldOpenGroupLinkInNewWindow',
  ShouldOpenThreadLinkInNewWindow = 'shouldOpenThreadLinkInNewWindow',
  ShouldOpenTagLinkInNewWindow = 'shouldOpenTagLinkInNewWindow',
  ShouldOpenTopicLinkInNewWindow = 'shouldOpenTopicLinkInNewWindow',
  ShouldOpenUserLinkInNewWindow = 'shouldOpenUserLinkInNewWindow',
  ShouldOpenViewConversationLinkInNewWindow = 'shouldOpenViewConversationLinkInNewWindow',
  ShouldOpenYammerLinkInNewWindow = 'shouldOpenYammerLinkInNewWindow',
  ShouldShowBackButton = 'shouldShowBackButton',
}

export type LinkRenderingOptions = Partial<Record<Scenarios, boolean>>;

export const defaultOptions: LinkRenderingOptions = {
  disableLinks: false,
  disableLivePersonaCard: false,
  disableUserContentLinks: false,
  disableReplyLinks: false,
  disableThreadLinks: false,
  isThreadStarterCopyButtonLinkExternal: false,
  shouldHideDownloadAttachmentLink: false,
  shouldHideLegacyPageLinks: false,
  shouldHideDescriptionMoreInfoLink: false,
  shouldOpenArticleViewLinkInNewWindow: false,
  shouldOpenArticlePublisherLinkInNewWindow: false,
  shouldOpenAskMeAnythingLinkInNewWindow: false,
  shouldOpenDiscoverCommunitiesLinkInNewWindow: false,
  shouldOpenEventLinkInNewWindow: false,
  shouldOpenGroupFilesInNewWindow: false,
  shouldOpenGroupFilesInLegacyPage: false,
  shouldOpenGroupHeaderSettingsLinkInNewWindow: false,
  shouldOpenGroupDeleteInNewWindow: false,
  shouldOpenGroupInfoLinkInNewWindow: false,
  shouldOpenGroupLinkInNewWindow: false,
  shouldOpenThreadLinkInNewWindow: false,
  shouldOpenTagLinkInNewWindow: false,
  shouldOpenTopicLinkInNewWindow: false,
  shouldOpenUserLinkInNewWindow: false,
  shouldOpenViewConversationLinkInNewWindow: false,
  shouldOpenYammerLinkInNewWindow: false,
  shouldShowBackButton: true,
};

export const LinkRenderingContext = createContext(defaultOptions);

export type UseLinkRenderingOptions = () => LinkRenderingOptions;
export const useLinkRenderingOptions: UseLinkRenderingOptions = () => useContext(LinkRenderingContext);
