import { searchPathPublisherQuestions } from '@ms/yammer-web-support/dist/urls';

export { searchPathPublisherQuestions };

export const searchLegacyPath = '/search/:type';
export const searchPath = '/search';

// eslint-disable-next-line no-restricted-syntax
export enum SearchPathType {
  Campaigns = 'campaigns',
  Files = 'files',
  Groups = 'groups',
  Inbox = 'inbox',
  Threads = 'threads',
  Questions = 'questions',
  Topics = 'topics',
  Users = 'users',
}

export const searchQueryTextKey = 'search';
export const searchQueryTypeKey = 'type';
export const searchQueryGroupScopeKey = 'groupScope';
export const searchQueryCampaignIdKey = 'campaignId';
export const searchQueryStartDateKey = 'start';
export const searchQueryEndDateKey = 'end';
export const searchQueryNetworkIdKey = 'networkId';
export const searchQueryParticipantUserIdsKey = 'participantUserIds';
export const searchQueryThreadStarterSenderIdKey = 'threadStarterSenderId';
export const searchQueryPostTypeKey = 'postType';
export const searchQueryTypeInboxValue = 'inbox';
