// eslint-disable-next-line no-restricted-syntax
export enum SearchResultType {
  All = 'All',
  Thread = 'Thread',
  Group = 'Group',
  User = 'User',
  File = 'File',
  Inbox = 'Inbox',
  Topic = 'Topic',
  NetworkQuestion = 'NetworkQuestion',
  PublisherNetworkQuestion = 'PublisherNetworkQuestion',
  Campaign = 'Campaign',
}

export type AllowedSearchResultType = Extract<
  SearchResultType,
  | SearchResultType.Thread
  | SearchResultType.Group
  | SearchResultType.User
  | SearchResultType.Campaign
  | SearchResultType.File
  | SearchResultType.Topic
>;

export interface SearchLocationParameters {
  readonly searchText?: string;
  readonly resultType?: SearchResultType;
  readonly groupScope?: string;
  readonly campaignId?: string;
  readonly startDate?: number;
  readonly endDate?: number;
  readonly networkId?: string;
  readonly participantUserIds?: string[];
  readonly threadStarterSenderId?: string;
  readonly postType?: string;
}
