import { Message, PlainMessage, ProtobufMessageClass, encodeMessage } from '@ms/yammer-protobuf';

import { reportTelemetryEvent } from '../report';
import { loadProtobufRequestFunctions } from '../report/protobuf/lazyExports';
import { AnalyticsV2EventProps } from '../report/protobuf/request/createAnalyticsV2Event';

import { reportInfo } from './info';

interface LoggingOptions {
  readonly eventName: string;
  readonly shouldReportErrors: boolean;
}

interface ReportUsageWithTypeNameOptions extends AnalyticsV2EventProps {
  readonly loggingOptions?: LoggingOptions;
}

export type ReportAnalyticsEvent = <T extends Message<T>>(
  MessageClass: ProtobufMessageClass<T>,
  plainMessage: PlainMessage<T>,
  loggingOptions?: LoggingOptions
) => void;
export const reportAnalyticsEvent: ReportAnalyticsEvent = (MessageClass, plainMessage, loggingOptions) => {
  const { value, typeName } = encodeMessage(MessageClass, plainMessage);
  reportUsageV2WithTypeName({ value, typeName, loggingOptions });
};

type ReportUsageV2WithTypeName = (options: ReportUsageWithTypeNameOptions) => void;
const reportUsageV2WithTypeName: ReportUsageV2WithTypeName = async ({ loggingOptions, ...analyticsOptions }) => {
  try {
    const { createAnalyticsV2Event } = await loadProtobufRequestFunctions();

    const analyticsV2Event = createAnalyticsV2Event(analyticsOptions);

    reportTelemetryEvent(analyticsV2Event);
  } catch (err) {
    if (loggingOptions?.shouldReportErrors) {
      reportInfo({
        eventName: 'analytics_v2_event_errored',
        eventProperties: {
          errorMessage: (err as Error).message,
          name: loggingOptions.eventName,
        },
      });
    }
  }
};
