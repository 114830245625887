import { makeStyles, shorthands } from '@griffel/react';

import { getGutterValue } from '../../util/styles/gutters';

export const useParagraphStyles = makeStyles({
  root: {
    ...shorthands.margin(0, 0, getGutterValue(4, true), 0),
    lineHeight: 'inherit',
  },
});
