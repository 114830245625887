import { schema } from 'normalizr';

import { processMemberNetworks, processMultiTenantOrganization } from '../strategies/multiTenantOrganization';

const memberNetworksSchema = new schema.Entity('networks', {}, { processStrategy: processMemberNetworks });

const memberNetworksEdgesSchema = [new schema.Object({ nodeId: memberNetworksSchema })];

export const multiTenantNetworkSchema = new schema.Entity(
  'multiTenantOrganization',
  {
    networks: memberNetworksEdgesSchema,
  },
  {
    idAttribute: (entity) => `${entity.hubNetwork.id}`,
    processStrategy: processMultiTenantOrganization,
  }
);
