import { Campaign } from '@ms/yammer-data/dist/state/types';

import { GetBaseUrlProps, getBaseUrl } from '../location';

type CampaignUrl = (id: Campaign['id']) => string;
export const campaignUrl: CampaignUrl = (campaignId) => `/campaigns/${campaignId}`;

type CampaignFullUrl = (groupId: Campaign['id'], host: GetBaseUrlProps['host']) => string;
export const campaignFullUrl: CampaignFullUrl = (groupId, host) => {
  const baseUrl = getBaseUrl({ host });

  return `${baseUrl}${campaignUrl(groupId)}`;
};
