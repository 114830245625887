import { lazyLoadOnRender } from '@ms/yammer-libs-lazy';

const GlobalCopilotNavButton = async () => {
  const { GlobalCopilotNavButton } = await import(
    /* webpackChunkName: "4-feat-global-copilot" */ './GlobalCopilotNavButton'
  );

  return { default: GlobalCopilotNavButton };
};

export const LoadableGlobalCopilotNavButton = lazyLoadOnRender({
  loader: GlobalCopilotNavButton,
});
