import {
  findById as findMultiTenantNetwork,
  getCurrentMtoHubNetwork,
  getCurrentMtoNetwork,
  getIsCurrentNetworkSpoke,
  getIsMtoAvailable,
  getIsMtoEnabled,
  getIsPermalinkDeeplinkEnabled,
  getMultiTenantMemberNetworksCount,
  getMultiTenantNetworks,
  getMultiTenantNetworksCount,
  getMultiTenantOrganization,
  getMultiTenantOrganizationStatus,
} from '@ms/yammer-data/dist/domains/multiTenantOrganization/selectors';
import { MultiTenantOrganizationNetwork } from '@ms/yammer-data/dist/state/types';

import { useSelector } from '../useSelector';

type UseGetMultiTenantNetworks = () => ReturnType<typeof getMultiTenantNetworks>;
export const useGetMultiTenantNetworks: UseGetMultiTenantNetworks = () =>
  useSelector((state) => getMultiTenantNetworks(state));

type UseFindMultiTenantNetwork = (
  networkId: MultiTenantOrganizationNetwork['id']
) => ReturnType<typeof findMultiTenantNetwork>;
export const useFindMultiTenantNetwork: UseFindMultiTenantNetwork = (networkId) =>
  useSelector((state) => findMultiTenantNetwork(state, networkId));

type UseCurrentMtoHubNetwork = () => ReturnType<typeof getCurrentMtoHubNetwork>;
export const useCurrentMtoHubNetwork: UseCurrentMtoHubNetwork = () =>
  useSelector((state) => getCurrentMtoHubNetwork(state));

type UseCurrentMtoNetwork = () => ReturnType<typeof getCurrentMtoNetwork>;
export const useCurrentMtoNetwork: UseCurrentMtoNetwork = () => useSelector((state) => getCurrentMtoNetwork(state));

type UseGetMultiTenantNetworkOrganization = () => ReturnType<typeof getMultiTenantOrganization>;
export const useGetMultiTenantNetworkOrganization: UseGetMultiTenantNetworkOrganization = () =>
  useSelector((state) => getMultiTenantOrganization(state));

type UseGetMultiTenantNetworkOrganizationStatus = () => ReturnType<typeof getMultiTenantOrganizationStatus>;
export const useGetMultiTenantNetworkOrganizationStatus: UseGetMultiTenantNetworkOrganizationStatus = () =>
  useSelector((state) => getMultiTenantOrganizationStatus(state));

type UseMtoEnabled = () => boolean;
export const useMtoEnabled: UseMtoEnabled = () => useSelector((state) => getIsMtoEnabled(state));

type UsePermalinkDeeplinkEnabled = () => boolean;
export const usePermalinkDeeplinkEnabled: UsePermalinkDeeplinkEnabled = () =>
  useSelector((state) => getIsPermalinkDeeplinkEnabled(state));

type UseMtoEnabledAndAvailable = () => boolean;
export const useMtoEnabledAndAvailable: UseMtoEnabledAndAvailable = () => {
  const isMtoAvailable = useSelector((state) => getIsMtoAvailable(state));
  const isMtoEnabled = useMtoEnabled();

  return isMtoAvailable && isMtoEnabled;
};

type UseMultiTenantNetworksCount = () => ReturnType<typeof getMultiTenantNetworksCount>;
export const useMultiTenantNetworksCount: UseMultiTenantNetworksCount = () =>
  useSelector((state) => getMultiTenantNetworksCount(state));

type UseMultiTenantMemberNetworksCount = () => ReturnType<typeof getMultiTenantMemberNetworksCount>;
export const useMultiTenantMemberNetworksCount: UseMultiTenantMemberNetworksCount = () =>
  useSelector((state) => getMultiTenantMemberNetworksCount(state));

type UseIsCurrentNetworkSpoke = () => ReturnType<typeof getIsCurrentNetworkSpoke>;
export const useIsCurrentNetworkSpoke: UseIsCurrentNetworkSpoke = () =>
  useSelector((state) => getIsCurrentNetworkSpoke(state));
