import { DismissPromptClientsVariables, DismissablePromptsClientsVariables } from '@ms/yammer-graphql';
import mutationDismissPrompt from '@ms/yammer-graphql/dist/operations/mutationDismissPrompt.graphql';
import queryDismissablePrompts from '@ms/yammer-graphql/dist/operations/queryDismissablePrompts.graphql';
import { RequestInitiator } from '@ms/yammer-telemetry-support';

import { GraphqlApiRequest, ManagedApiLifecycle, getGraphqlApiRequest, managedApiAction } from '../..';
import { normalizeDismissablePromptsResponse } from '../../normalization/graphql/normalizeDismissablePrompt';
import { DismissPromptResponse, DismissablePromptsResponse } from '../../normalization/types';
import {
  DismissablePrompt,
  DismissablePromptKey,
  DismissablePromptType,
  DismissablePromptTypeEntity,
  EntityStatus,
  YammerState,
} from '../../state/types';
import { reportError } from '../../telemetry';
import { PromiseThunkAction } from '../../types';
import { getStatusForId as getDismissablePromptTypeStatusForId } from '../dismissablePromptsByType/selectors';

import {
  DismissPromptFulfilledAction,
  DismissPromptPendingAction,
  DismissPromptRejectedAction,
  LoadDismissablePromptsFulfilledAction,
  LoadDismissablePromptsPendingAction,
  LoadDismissablePromptsRejectedAction,
  SetCurrentPrompt,
} from './actions.types';
import { getStatusForId as getDismissablePromptKeyStatusForId } from './selectors';

const dismissPromptPending = (promptKey: DismissablePromptKey): DismissPromptPendingAction => ({
  type: 'DISMISSABLE_PROMPTS.DISMISS_PROMPT_PENDING',
  payload: {
    promptKey,
  },
});

const dismissPromptFulfilled = (promptKey: DismissablePromptKey): DismissPromptFulfilledAction => ({
  type: 'DISMISSABLE_PROMPTS.DISMISS_PROMPT_FULFILLED',
  payload: {
    promptKey,
  },
});

const dismissPromptRejected = (error: unknown, promptKey: DismissablePromptKey): DismissPromptRejectedAction => ({
  type: 'DISMISSABLE_PROMPTS.DISMISS_PROMPT_REJECTED',
  payload: {
    error,
    promptKey,
  },
  error: true,
});

export const setCurrentlyVisiblePromptKey = (currentlyVisiblePromptKey: DismissablePromptKey): SetCurrentPrompt => ({
  type: 'DISMISSABLE_PROMPTS.SET_CURRENTLY_VISIBLE_PROMPT',
  payload: { currentlyVisiblePromptKey },
});

const updateDismissablePromptRequest = (
  promptKey: DismissablePromptKey
): GraphqlApiRequest<DismissPromptClientsVariables> =>
  getGraphqlApiRequest({
    body: {
      operationName: 'DismissPromptClients',
      query: mutationDismissPrompt,
      variables: {
        promptKey,
      },
    },
  });

const updateDismissablePromptLifecycle = (
  promptKey: DismissablePromptKey
): ManagedApiLifecycle<DismissPromptResponse> => ({
  pending: () => dismissPromptPending(promptKey),
  fulfilled: () => dismissPromptFulfilled(promptKey),
  rejected: (error) => dismissPromptRejected(error, promptKey),
});

export type UpdateDismissablePrompt = (promptKey: DismissablePromptKey) => PromiseThunkAction<void>;
export const updateDismissablePrompt: UpdateDismissablePrompt = (promptKey) => async (dispatch) => {
  const request = updateDismissablePromptRequest(promptKey);
  const lifecycle = updateDismissablePromptLifecycle(promptKey);
  const action = managedApiAction({ request, lifecycle });

  try {
    await dispatch(action);
  } catch (error) {
    reportError({ error });
  }
};

const loadDismissablePromptsPending = (
  promptKeys: DismissablePromptKey[],
  promptTypes: DismissablePromptType[]
): LoadDismissablePromptsPendingAction => ({
  type: 'DISMISSABLE_PROMPTS.LOAD_PENDING',
  payload: {
    promptKeys,
    promptTypes,
  },
});

const loadDismissablePromptsFulfilled = (entities: {
  readonly dismissablePrompts: Record<string, DismissablePrompt>;
  readonly dismissablePromptsByType: Record<string, DismissablePromptTypeEntity>;
}): LoadDismissablePromptsFulfilledAction => ({
  type: 'DISMISSABLE_PROMPTS.LOAD_FULFILLED',
  payload: {
    entities,
  },
});

const loadDismissablePromptsRejected = (
  promptKeys: DismissablePromptKey[],
  promptTypes: DismissablePromptType[],
  error: unknown
): LoadDismissablePromptsRejectedAction => ({
  type: 'DISMISSABLE_PROMPTS.LOAD_REJECTED',
  payload: {
    promptKeys,
    promptTypes,
    error,
  },
  error: true,
});

const loadDismissablePromptsRequest = (
  {
    promptKeys,
    promptTypes,
  }: {
    readonly promptKeys: DismissablePromptKey[];
    readonly promptTypes: DismissablePromptType[];
  },
  initiator?: RequestInitiator
): GraphqlApiRequest<DismissablePromptsClientsVariables> =>
  getGraphqlApiRequest({
    body: {
      operationName: 'DismissablePromptsClients',
      query: queryDismissablePrompts,
      variables: {
        promptKeys,
        promptTypes,
      },
    },
    initiator,
  });

const loadDismissablePromptsLifecycle = ({
  promptKeys,
  promptTypes,
}: {
  readonly promptKeys: DismissablePromptKey[];
  readonly promptTypes: DismissablePromptType[];
}): ManagedApiLifecycle<DismissablePromptsResponse> => ({
  pending: () => loadDismissablePromptsPending(promptKeys, promptTypes),
  fulfilled: (response) => loadDismissablePromptsFulfilled(normalizeDismissablePromptsResponse(response)),
  rejected: (error) => loadDismissablePromptsRejected(promptKeys, promptTypes, error),
});

type GetDismissablePromptKeysNotInState = <KindOfPrompt>(
  promptKeys: KindOfPrompt[],
  state: YammerState,
  getPromptStatusFn: (state: YammerState, id: KindOfPrompt) => EntityStatus
) => KindOfPrompt[];
const getDismissablePromptsNotInState: GetDismissablePromptKeysNotInState = (promptKeys, state, getStatusFn) => {
  const requestStatuses = promptKeys.map((key) => ({
    status: getStatusFn(state, key),
    key,
  }));

  const prompts = requestStatuses
    .filter((promptData) => promptData.status !== 'pending' && promptData.status !== 'fulfilled')
    .map((promptData) => promptData.key);

  return prompts;
};

export type LoadDismissablePrompts = (
  {
    promptKeys,
    promptTypes,
  }: {
    readonly promptKeys?: DismissablePromptKey[];
    readonly promptTypes?: DismissablePromptType[];
  },
  initiator?: RequestInitiator
) => PromiseThunkAction<void>;
export const loadDismissablePrompts: LoadDismissablePrompts =
  ({ promptKeys = [], promptTypes = [] }, initiator) =>
  async (dispatch, getState) => {
    const state = getState();
    const promptKeysNotLoaded = getDismissablePromptsNotInState<DismissablePromptKey>(
      promptKeys,
      state,
      getDismissablePromptKeyStatusForId
    );
    const promptTypesNotLoaded = getDismissablePromptsNotInState<DismissablePromptType>(
      promptTypes,
      state,
      getDismissablePromptTypeStatusForId
    );

    if (promptKeysNotLoaded.length === 0 && promptTypesNotLoaded.length === 0) {
      return;
    }

    const payload = { promptKeys: promptKeysNotLoaded, promptTypes: promptTypesNotLoaded };

    const request = loadDismissablePromptsRequest(payload, initiator);
    const lifecycle = loadDismissablePromptsLifecycle(payload);
    const action = managedApiAction({ request, lifecycle });

    try {
      await dispatch(action);
    } catch (error) {
      reportError({ error });
    }
  };
