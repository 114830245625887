import { stringify } from 'querystring';

import { GroupFeedThreadSortOrder, GroupFeedType } from '@ms/yammer-data/dist/normalization/types';
import { Group, GroupFeedParameters } from '@ms/yammer-data/dist/state/types';

import { GetBaseUrlProps, getBaseUrl } from '../location';

type GroupPage = (groupId: Group['id']) => string;
export const groupPage: GroupPage = (groupId) => `/groups/${groupId}`;
export const groupFeedPage: GroupPage = (groupId) => `/groups/${groupId}/feed`;
export const groupNewFeedPage: GroupPage = (groupId) => `/groups/${groupId}/new`;
export const groupAllFeedPage: GroupPage = (groupId) => `/groups/${groupId}/all`;
export const groupAboutPage: GroupPage = (groupId) => `/groups/${groupId}/about`;
export const groupEventsPage: GroupPage = (groupId) => `/groups/${groupId}/events`;
export const groupQuestionsPage: GroupPage = (groupId) => `/groups/${groupId}/questions`;
export const groupUnansweredQuestionsPage: GroupPage = (groupId) => `/groups/${groupId}/unanswered`;
export const groupNoRepliesQuestionsPage: GroupPage = (groupId) => `/groups/${groupId}/norepliesquestions`;
export const groupSettingsPage: GroupPage = (groupId) => `/groups/${groupId}/settings`;
export const groupDeletePage: GroupPage = (groupId) => `/groups/${groupId}/delete`;
export const groupInsightsPage: GroupPage = (groupId) => `/groups/${groupId}/activity/overview`;
export const groupInsightsPageV2: GroupPage = (groupId) => `/groups/${groupId}/activity/overview/v2`;
export const groupQnAInsightsPage: GroupPage = (groupId) => `/groups/${groupId}/activity/qna`;
export const legacyGroupInsightsPage: GroupPage = (groupId) => `/groups/${groupId}/insights`;
export const groupFilesPage: GroupPage = (groupId) => `/groups/${groupId}/files`;
export const groupInfoPage: GroupPage = (groupId) => `/groups/${groupId}/info`;
export const groupSuggestedContentPage: GroupPage = (groupId) => `/groups/${groupId}/m365-copilot-suggested-content`;
export const groupTopQuestionsPage: GroupPage = (groupId) => `/groups/${groupId}/top-questions`;

export const groupNewKey = 'new';
export const groupTypeKey = 'postType';
export const groupSortByKey = 'sortBy';

type GroupPageUrl = (groupId: Group['id'], host: GetBaseUrlProps['host']) => string;
export const groupPageUrl: GroupPageUrl = (groupId, host) => {
  const baseUrl = getBaseUrl({ host });

  return `${baseUrl}${groupPage(groupId)}`;
};

type ConvertGroupDatabaseIdToUrlId = (groupDatabaseId: Group['databaseId']) => Group['databaseId'];
export const convertGroupDatabaseIdToUrlId: ConvertGroupDatabaseIdToUrlId = (groupDatabaseId) =>
  groupDatabaseId === '0' ? 'company' : groupDatabaseId;

type GroupFeedPageWithParameters = (groupId: Group['id'], groupParameters: GroupFeedParameters) => string;
export const groupFeedPageWithParameters: GroupFeedPageWithParameters = (groupId, groupParameters) =>
  `${groupFeedPage(groupId)}${makeQueryStringWithGroupFeedParameters(groupParameters)}`;

type MakeQueryStringWithGroupFeedParameters = (groupFeedParameters: GroupFeedParameters) => string;
export const makeQueryStringWithGroupFeedParameters: MakeQueryStringWithGroupFeedParameters = ({
  isNew,
  postType,
  sortBy,
}) => {
  const isNewParameter = isNew ? { [groupNewKey]: isNew } : { [groupNewKey]: false };
  const postTypeParameter = postType ? { [groupTypeKey]: postType } : { [groupTypeKey]: GroupFeedType.ALL };
  const sortByParameter = sortBy
    ? { [groupSortByKey]: sortBy }
    : { [groupSortByKey]: GroupFeedThreadSortOrder.UPDATED_AT };

  const queryParameters = { ...isNewParameter, ...postTypeParameter, ...sortByParameter };

  return `?${stringify(queryParameters)}`;
};

type GroupSuggestedArticlePage = (props: { readonly groupId: Group['id']; readonly articleName: string }) => string;
export const groupSuggestedArticlePage: GroupSuggestedArticlePage = ({ groupId, articleName }) =>
  `/groups/${groupId}/suggested-article?${stringify({ articleName })}`;
