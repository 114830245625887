import { useMemo } from 'react';

import { useCanAccessCommunicationsDashboard } from '../../featureChecks';
import { UseMainUIFeatureSettings, uiFeatureAccessSymbol } from '../../types';

export const useCommsDashboardDigitalSafetyPageSettings: UseMainUIFeatureSettings<
  'communicationsDashboardDigitalSafetyPage'
> = (options) => {
  const canAccessDashboard = useCanAccessCommunicationsDashboard();

  return useMemo(() => {
    const overrides = options?.overrides?.communicationsDashboardDigitalSafetyPage ?? {};

    return {
      [uiFeatureAccessSymbol]: canAccessDashboard,
      ...overrides,
    };
  }, [options, canAccessDashboard]);
};
