import { normalize } from 'normalizr';

import { DismissablePromptsSchema, dismissablePromptsSchema } from './schemas/dismissablePrompt';
import { LoadDismissablePromptsEntities, NormalizedDismissablePromptsEntities } from './types';

export const normalizeDismissablePromptsResponse: LoadDismissablePromptsEntities = (response) =>
  normalize<DismissablePromptsSchema, NormalizedDismissablePromptsEntities>(
    response.data.viewer,
    dismissablePromptsSchema
  ).entities;
