import {
  getAllowedUserAdminRoleAssignments,
  getCanAccessThreadExtendedActivity,
  getCanAssignUserAdminRoles,
  getCanDesignateLeaders,
  getCanRemoveAnswersTopics,
  getCanShowMobileCrossSell,
  getEditNetworkQuestionsSettingsStatus,
  getFeatureAccessSettings,
  getFeatureAccessSettingsStatus,
  getIsGroupQuestionsAIImporterEnabled,
  getIsNetworkQuestionsAIFeaturesEnabled,
  getIsNetworkQuestionsAIImporterEnabled,
  getNetworkQuestionsEnablementMode,
  getViewerCanAccessCopilotInM365CopilotAdoptionGroup,
} from '@ms/yammer-data/dist/domains/featureAccessSettings/selectors';
import { NetworkQuestionsEnablementMode } from '@ms/yammer-graphql';

import { useSelector } from '../useSelector';

type UseFeatureAccessSettings = () => ReturnType<typeof getFeatureAccessSettings>;
export const useFeatureAccessSettings: UseFeatureAccessSettings = () => useSelector(getFeatureAccessSettings);

type UseFeatureAccessSettingsStatus = () => ReturnType<typeof getFeatureAccessSettingsStatus>;
export const useFeatureAccessSettingsStatus: UseFeatureAccessSettingsStatus = () =>
  useSelector(getFeatureAccessSettingsStatus);

type UseNetworkQuestionsEnablementMode = () => NetworkQuestionsEnablementMode | undefined;
export const useNetworkQuestionsEnablementMode: UseNetworkQuestionsEnablementMode = () =>
  useSelector(getNetworkQuestionsEnablementMode);

type UseIsGroupQuestionsAIImporterEnabled = () => boolean | undefined;
export const useIsGroupQuestionsAIImporterEnabled: UseIsGroupQuestionsAIImporterEnabled = () =>
  useSelector(getIsGroupQuestionsAIImporterEnabled);

type UseIsNetworkQuestionsAIFeaturesEnabled = () => boolean | undefined;
export const useIsNetworkQuestionsAIFeaturesEnabled: UseIsNetworkQuestionsAIFeaturesEnabled = () =>
  useSelector(getIsNetworkQuestionsAIFeaturesEnabled);

type UseIsNetworkQuestionsAIImporterEnabled = () => boolean | undefined;
export const useIsNetworkQuestionsAIImporterEnabled: UseIsNetworkQuestionsAIImporterEnabled = () =>
  useSelector(getIsNetworkQuestionsAIImporterEnabled);

type UseEditNetworkQuestionsSettingsStatus = () => ReturnType<typeof getEditNetworkQuestionsSettingsStatus>;
export const useEditNetworkQuestionsSettingsStatus: UseEditNetworkQuestionsSettingsStatus = () =>
  useSelector(getEditNetworkQuestionsSettingsStatus);

type UseCanRemoveAnswersTopics = () => ReturnType<typeof getCanRemoveAnswersTopics>;
export const useCanRemoveAnswersTopics: UseCanRemoveAnswersTopics = () => useSelector(getCanRemoveAnswersTopics);

type UseCanAssignUserAdminRoles = () => ReturnType<typeof getCanAssignUserAdminRoles>;
export const useCanAssignUserAdminRoles: UseCanAssignUserAdminRoles = () => useSelector(getCanAssignUserAdminRoles);

type UseAllowedUserAdminRoleAssignments = () => ReturnType<typeof getAllowedUserAdminRoleAssignments>;
export const useAllowedUserAdminRoleAssignments: UseAllowedUserAdminRoleAssignments = () =>
  useSelector(getAllowedUserAdminRoleAssignments);

type UseCanDesignateLeaders = () => ReturnType<typeof getCanDesignateLeaders>;
export const useCanDesignateLeaders: UseCanDesignateLeaders = () => useSelector(getCanDesignateLeaders);

type UseCanAccessThreadExtendedActivity = () => ReturnType<typeof getCanAccessThreadExtendedActivity>;
export const useCanAccessThreadExtendedActivity: UseCanAccessThreadExtendedActivity = () =>
  useSelector(getCanAccessThreadExtendedActivity);

type UseCanShowMobileCrossSell = () => ReturnType<typeof getCanShowMobileCrossSell>;
export const useCanShowMobileCrossSell: UseCanShowMobileCrossSell = () => useSelector(getCanShowMobileCrossSell);

type UseGetViewerCanAccessCopilotInM365CopilotAdoptionGroup = () => ReturnType<
  typeof getViewerCanAccessCopilotInM365CopilotAdoptionGroup
>;
export const useGetViewerCanAccessCopilotInM365CopilotAdoptionGroup: UseGetViewerCanAccessCopilotInM365CopilotAdoptionGroup =
  () => useSelector(getViewerCanAccessCopilotInM365CopilotAdoptionGroup);
