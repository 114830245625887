import { ParsedUrlQuery, stringify } from 'querystring';

import { SearchLocationParameters, SearchResultType } from '../types';

import {
  searchPath,
  searchQueryCampaignIdKey,
  searchQueryEndDateKey,
  searchQueryGroupScopeKey,
  searchQueryNetworkIdKey,
  searchQueryParticipantUserIdsKey,
  searchQueryPostTypeKey,
  searchQueryStartDateKey,
  searchQueryTextKey,
  searchQueryThreadStarterSenderIdKey,
  searchQueryTypeKey,
} from './urls';

const typeUrlParamToSearchResultType: Record<string, SearchResultType> = {
  all: SearchResultType.All,
  threads: SearchResultType.Thread,
  questions: SearchResultType.NetworkQuestion,
  groups: SearchResultType.Group,
  users: SearchResultType.User,
  campaigns: SearchResultType.Campaign,
  files: SearchResultType.File,
  topics: SearchResultType.Topic,
  inbox: SearchResultType.Inbox,
};

export const resultTypeToTypeUrlParam: Record<SearchResultType, string> = {
  [SearchResultType.All]: 'all',
  [SearchResultType.Thread]: 'threads',
  [SearchResultType.NetworkQuestion]: 'questions',
  [SearchResultType.PublisherNetworkQuestion]: 'questions',
  [SearchResultType.Group]: 'groups',
  [SearchResultType.User]: 'users',
  [SearchResultType.Campaign]: 'campaigns',
  [SearchResultType.File]: 'files',
  [SearchResultType.Topic]: 'topics',
  [SearchResultType.Inbox]: 'inbox',
};

export const getSearchResultTypeFromTypeUrlParam = (type: string): SearchResultType =>
  typeUrlParamToSearchResultType[type];

export const getTypeUrlParamFromSearchResultType = (type: SearchResultType): string => resultTypeToTypeUrlParam[type];

type SearchPageWithParameters = (searchParameters: SearchLocationParameters) => string;
export const searchPageWithParameters: SearchPageWithParameters = (searchParameters) => {
  const queryParameters = makeSearchQueryStringParameters(searchParameters);
  const searchParams = stringify(queryParameters as unknown as ParsedUrlQuery);

  return `${searchPath}${searchParams ? '?' : ''}${searchParams}`;
};

export interface SearchQueryParameters {
  readonly [searchQueryTextKey]?: string;
  readonly [searchQueryTypeKey]?: string;
  readonly [searchQueryGroupScopeKey]?: string;
  readonly [searchQueryCampaignIdKey]?: string;
  readonly [searchQueryStartDateKey]?: number;
  readonly [searchQueryEndDateKey]?: number;
  readonly [searchQueryNetworkIdKey]?: string;
  readonly [searchQueryParticipantUserIdsKey]?: string;
  readonly [searchQueryThreadStarterSenderIdKey]?: string;
  readonly [searchQueryPostTypeKey]?: string;
}

type MakeQueryObjectWithSearchParameters = (searchParameters: SearchLocationParameters) => SearchQueryParameters;
export const makeSearchQueryStringParameters: MakeQueryObjectWithSearchParameters = ({
  searchText,
  resultType,
  groupScope,
  campaignId,
  startDate,
  endDate,
  networkId,
  participantUserIds,
  threadStarterSenderId,
  postType,
}) => {
  const searchTextParameter = searchText ? { [searchQueryTextKey]: searchText } : {};
  const typeParameter = resultType ? { [searchQueryTypeKey]: getTypeUrlParamFromSearchResultType(resultType) } : {};
  const groupScopeParameter = groupScope ? { [searchQueryGroupScopeKey]: groupScope } : {};
  const campaignIdParameter = campaignId ? { [searchQueryCampaignIdKey]: campaignId } : {};
  const startDateParameter = startDate ? { [searchQueryStartDateKey]: startDate } : {};
  const endDateParameter = endDate ? { [searchQueryEndDateKey]: endDate } : {};
  const networkIdParameter = networkId ? { [searchQueryNetworkIdKey]: networkId } : {};
  const participantUserIdsParameter =
    participantUserIds && participantUserIds.length > 0
      ? { [searchQueryParticipantUserIdsKey]: participantUserIds.join(',') }
      : {};
  const threadStarterSenderIdParameter = threadStarterSenderId
    ? { [searchQueryThreadStarterSenderIdKey]: threadStarterSenderId }
    : {};
  const postTypeParameter = postType ? { [searchQueryPostTypeKey]: postType } : {};

  return {
    ...searchTextParameter,
    ...typeParameter,
    ...groupScopeParameter,
    ...campaignIdParameter,
    ...startDateParameter,
    ...endDateParameter,
    ...networkIdParameter,
    ...participantUserIdsParameter,
    ...threadStarterSenderIdParameter,
    ...postTypeParameter,
  };
};
