import { noop } from 'lodash';
import React, { FC, createContext, memo, useCallback, useContext, useMemo, useState } from 'react';

export interface GlobalCopilotState {
  readonly isOpen: boolean;
  toggleOpen(): void;
}

const disabledState: GlobalCopilotState = {
  isOpen: false,
  toggleOpen: noop,
};

type UseGlobalCopilotState = () => GlobalCopilotState;
const useGlobalCopilotState: UseGlobalCopilotState = () => {
  const [isOpen, setIsOpen] = useState<GlobalCopilotState['isOpen']>(false);

  const toggleOpen = useCallback(() => setIsOpen((prev) => !prev), []);

  return useMemo(() => ({ isOpen, toggleOpen }), [isOpen, toggleOpen]);
};

const GlobalCopilotContext = createContext<GlobalCopilotState>(disabledState);
export const GlobalCopilotProvider: FC = memo(({ children }) => {
  const globalCopilot = useGlobalCopilotState();

  return <GlobalCopilotContext.Provider value={globalCopilot}>{children}</GlobalCopilotContext.Provider>;
});

export const useGlobalCopilotContext = () => useContext(GlobalCopilotContext);
