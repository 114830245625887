import { FeatureRollout } from '@ms/yammer-data/dist/state/ecsFeatureRollouts/types';

import { useIsFeatureRolloutEnabled } from '../featureRollouts';
import { useCurrentUser } from '../users';

type UseCanCurrentUserAccessEnhancedUserProfile = () => boolean;
export const useCanCurrentUserAccessEnhancedUserProfile: UseCanCurrentUserAccessEnhancedUserProfile = () => {
  const isEnhancedUserProfileEnabled = useIsFeatureRolloutEnabled(FeatureRollout.EnhancedUserProfiles);
  const { isGuest: isCurrentUserGuest } = useCurrentUser();

  return isEnhancedUserProfileEnabled && !isCurrentUserGuest;
};
