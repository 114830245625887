import { makeStyles, mergeClasses, shorthands } from '@griffel/react';

import { Fluent9ComponentClassNames } from '../../util/classNames';
// To be resolved with: https://dev.azure.com/yammer/engineering/_workitems/edit/286123
// eslint-disable-next-line no-restricted-imports
import { addAlpha, getSemanticPaletteColor } from '../../util/colors';
import {
  getFluentFontSizeTokenFromNumericFontSize,
  getFluentFontSizeTokenFromPreset,
  useDisplayClassName,
  useTextTransformClassName,
  useTypographyPresetClassName,
} from '../../util/styles/fluent9';
import { TextTransform, textColors } from '../../util/styles/fonts';
// To be resolved with: https://dev.azure.com/yammer/engineering/_workitems/edit/286123
// eslint-disable-next-line no-restricted-imports
import { ITheme } from '../../util/styling';
import { createTokens } from '../../util/theme/fluent9';

import { TextProps } from './Text.types';

const { tokens, cssVariables } = createTokens(['color', 'backgroundColor', 'iconSize'], 'text');

/**
 * Remove when icon sizing get simplified:
 * https://dev.azure.com/yammer/engineering/_workitems/edit/273336
 */
const getFontSizeFromPresetOrSize = (preset: TextProps['preset'], size: TextProps['size']) => {
  if (preset) {
    return getFluentFontSizeTokenFromPreset(preset);
  }

  if (size) {
    return getFluentFontSizeTokenFromNumericFontSize(size);
  }
};

export interface TextClassNameProps {
  readonly backgroundColor?: TextProps['backgroundColor'];
  readonly color?: TextProps['color'];
  readonly display?: TextProps['display'];
  readonly font?: TextProps['font'];
  readonly preset?: TextProps['preset'];
  readonly size?: TextProps['size'];
  readonly uppercase?: TextProps['uppercase'];
  readonly weight?: TextProps['weight'];
  readonly className?: TextProps['className'];
}

type UseClassNames = (props: TextClassNameProps) => Fluent9ComponentClassNames<'text'>;
export const useClassNames: UseClassNames = ({
  backgroundColor,
  color,
  display,
  font,
  preset,
  size,
  uppercase,
  weight,
  className,
}) => {
  const textClassNames = useTextClassNames();
  const textTransform = uppercase ? TextTransform.Uppercase : undefined;

  const hasSize = !!preset || !!size;

  return {
    text: mergeClasses(
      textClassNames.root,
      backgroundColor && textClassNames.backgroundColor,
      color && textClassNames.color,
      useDisplayClassName(display),
      !font && textClassNames.inheritableFontFamily,
      hasSize && textClassNames.nestedSVGIconSize,
      !hasSize && textClassNames.inheritableSize,
      useTextTransformClassName(textTransform),
      !weight && textClassNames.inheritableWeight,
      useTypographyPresetClassName(preset),
      className
    ),
  };
};

const useTextClassNames = makeStyles({
  /**
   * These reset styles can be removed when FUI9 Text provides a `disableDefaultStyling` prop:
   * https://github.com/microsoft/fluentui/issues/29153
   */
  root: {
    whiteSpace: 'unset',
    ...shorthands.overflow('unset'),
    textAlign: 'unset',
    textOverflow: 'unset',
  },
  inheritableFontFamily: {
    fontFamily: 'inherit',
  },
  inheritableSize: {
    fontSize: 'inherit',
    lineHeight: 'inherit',
  },
  inheritableWeight: {
    fontWeight: 'inherit',
  },
  color: {
    color: tokens.color,
  },
  backgroundColor: {
    backgroundColor: tokens.backgroundColor,
  },
  /**
   * Remove when icon sizing get simplified:
   * https://dev.azure.com/yammer/engineering/_workitems/edit/273336
   */
  nestedSVGIconSize: {
    '& .y-icon, & .y-fluent-icon': {
      height: tokens.iconSize,
      width: tokens.iconSize,
    },
  },
});

interface GetInlineStylesProps {
  readonly size?: TextProps['size'];
  readonly preset?: TextProps['preset'];
  readonly color: TextProps['color'];
  readonly backgroundColor?: TextProps['backgroundColor'];
  readonly backgroundAlpha: number;
  /**
   * @deprecated Will be removed with https://dev.azure.com/yammer/engineering/_workitems/edit/271043
   */
  readonly theme: ITheme;
}
export const getInlineStyles = ({
  size,
  preset,
  color,
  backgroundColor,
  backgroundAlpha,
  theme,
}: GetInlineStylesProps) => {
  const backgroundColorFromTheme = backgroundColor ? getSemanticPaletteColor(theme, backgroundColor) : undefined;
  const backgroundColorWithBackgroundAlpha = backgroundColorFromTheme
    ? addAlpha(backgroundColorFromTheme, backgroundAlpha)
    : undefined;

  return {
    [cssVariables.color]: color ? textColors(theme)[color] : undefined,
    [cssVariables.backgroundColor]: backgroundColorWithBackgroundAlpha,
    [cssVariables.iconSize]: getFontSizeFromPresetOrSize(preset, size),
  };
};
