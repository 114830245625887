import { schema } from 'normalizr';

import { processDismissablePrompt } from '../strategies/dismissablePrompt';

const dismissablePromptSchema = new schema.Entity(
  'dismissablePrompts',
  {},
  { idAttribute: 'promptKey', processStrategy: processDismissablePrompt }
);

const dismissablePromptsByTypeSchema = new schema.Entity(
  'dismissablePromptsByType',
  {},
  { idAttribute: 'promptKey', processStrategy: processDismissablePrompt }
);

const promptSchema = new schema.Object({
  dismissablePrompts: [dismissablePromptSchema],
  dismissablePromptsByType: [dismissablePromptsByTypeSchema],
});

export type DismissablePromptsSchema = typeof promptSchema;
export const dismissablePromptsSchema = promptSchema;
