import { useMemo } from 'react';

import { FlairColor, PaletteColor, getFlairColor } from './colors';
import { useFlairColorContext } from './colors/FlairColorContextProvider';
import { Display } from './styles/display';
import { FocusOutlineProps } from './styles/focusOutlines';
import { IPalette, IStyle, ITheme, IsFocusVisibleClassName } from './styling';
import { Themed } from './theme';

export type { Display };

export interface LinkStyleProps extends FocusOutlineProps {
  readonly className?: string;
  readonly display?: Display;
  readonly unstyled?: boolean;
  readonly color?: LinkColor;
  readonly activeColor?: LinkColor;
  readonly colorOverride?: FlairColor;
  readonly isOnDarkBackground?: boolean;
  readonly alwaysUnderline?: boolean;
}
export type ThemedLinkStyleProps = Themed<LinkStyleProps>;

export interface ClickableStyleProps extends LinkStyleProps {
  readonly disabled?: boolean;
}

export type LinkColor = 'default' | 'bodyText' | 'blue' | 'theme' | 'white';

const linkColorToPaletteColorMapping: Record<LinkColor, PaletteColor> = {
  default: 'neutralSecondary',
  bodyText: 'neutralDark',
  blue: 'accent',
  theme: 'themeDark',
  white: 'white',
};

const getLinkColor = (palette: IPalette, value: LinkColor) => palette[linkColorToPaletteColorMapping[value]];

export type ThemedClickableStyleProps = Themed<ClickableStyleProps>;
export const useLinkStyles: (styleProps: ThemedClickableStyleProps) => IStyle = ({
  display = 'inline',
  theme,
  color = 'default',
  activeColor = 'blue',
  colorOverride,
  unstyled = false,
  disabled = false,
  alwaysUnderline = false,
}) => {
  const { isOnDarkBackground } = useFlairColorContext();

  const styles = getStyles(
    color,
    activeColor,
    unstyled,
    disabled,
    alwaysUnderline,
    theme,
    colorOverride,
    isOnDarkBackground
  );

  return useMemo(
    () => ({
      display,
      border: 'none',
      background: 'none',
      font: 'inherit',
      margin: 0,
      padding: 0,
      textAlign: 'inherit',
      lineHeight: 'inherit',
      overflow: 'visible',
      ...styles.base,
      selectors: {
        ':hover': styles.active,
        ':hover .y-fakeLink': styles.fakeLinkActive,
        ':hover .y-fakeLink.noUnderline, :focus .y-fakeLink.noUnderline': {
          textDecoration: 'none',
        },
        [`.${IsFocusVisibleClassName} &:focus`]: styles.active,
        [`.${IsFocusVisibleClassName} &:focus .y-fakeLink`]: styles.fakeLinkActive,
        '.y-fakeLink.noLinkColor': {
          color: 'inherit',
        },
        '.y-fakeLink': styles.fakeLink,
      },
    }),
    [display, styles]
  );
};

const getStyles = (
  color: LinkColor,
  activeColor: LinkColor,
  unstyled: boolean,
  disabled: boolean,
  alwaysUnderline: boolean,
  theme: ITheme,
  colorOverride?: FlairColor,
  isOnDarkBackground?: boolean
) => {
  const { palette } = theme;

  const defaults = {
    base: {
      color: colorOverride
        ? getFlairColor(colorOverride, theme.isInverted, isOnDarkBackground)
        : getLinkColor(palette, color),
      textDecoration: alwaysUnderline ? 'underline' : 'none',
      cursor: 'pointer',
    },
    active: {
      color: colorOverride
        ? getFlairColor(colorOverride, theme.isInverted, isOnDarkBackground)
        : getLinkColor(palette, activeColor),
      textDecoration: 'underline',
    },
    fakeLink: {
      color: colorOverride
        ? getFlairColor(colorOverride, theme.isInverted, isOnDarkBackground)
        : getLinkColor(palette, color),
    },
    fakeLinkActive: {
      color: colorOverride
        ? getFlairColor(colorOverride, theme.isInverted, isOnDarkBackground)
        : getLinkColor(palette, activeColor),
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  };

  if (disabled) {
    return {
      base: {
        color: palette.neutralQuaternary,
        textDecoration: 'none',
        cursor: 'auto',
      },
      active: {
        color: palette.neutralQuaternary,
        textDecoration: 'none',
      },
      fakeLink: {
        color: palette.neutralQuaternary,
      },
      fakeLinkActive: {
        color: palette.neutralQuaternary,
        textDecoration: 'none',
        cursor: 'auto',
      },
    };
  }

  if (unstyled) {
    return {
      base: {
        ...defaults.base,
        color: 'inherit',
      },
      active: {
        ...defaults.active,
        textDecoration: 'none',
        color: 'inherit',
      },
      fakeLink: defaults.fakeLink,
      fakeLinkActive: defaults.fakeLinkActive,
    };
  }

  return defaults;
};
