export const CoreUIColors = {
  blueTint30: '#5CAAE5',
  blueTint40: '#A9D3F2',
  blueTint50: '#D0E7F8',
  blueTint60: '#F3F9FD',
  blueShade10: '#006CBF',
  blueShade20: '#005BA1',
  blueShade40: '#002440',
  lightBlueTint50: '#DCEDFA',
  lightBlueShade40: '#112D42',
  royalBlueShade20: '#003B6A',
  royalBluePrimary: '#004E8C',
  darkBluePrimary: '#003966',
  magentaPrimary: '#BF0077',
  magentaShade10: '#AC006B',
  magentaTint40: '#ECA5D1',
  purplePrimary: '#5C2E91',
  tealPrimary: '#038387',
};
