import React, { FC } from 'react';

import { BorderRadius } from '../../util/styles/borders';
import { useTheme } from '../../util/theme';
import Clickable from '../Clickable';
import Icon from '../Icon';
import Image from '../Image';
import InternalLink from '../InternalLink';

import { getClassNames } from './IconButton.styles.legacy';
import { IconButtonProps, NamedIconButtonProps } from './IconButton.types';

const isNamedIconButtonProps = (iconProp: IconButtonProps): iconProp is NamedIconButtonProps =>
  !!(iconProp as NamedIconButtonProps).iconName;

/**
 * `IconButton` is a square button which displays an icon.
 */
export const IconButton: FC<IconButtonProps> = (props) => {
  const {
    ariaLabel,
    backgroundColor,
    backgroundHoverColor,
    useTransparentBackground = false,
    borderRadius = BorderRadius.Round,
    className,
    role,
    disabled,
    size = 18,
    onClick,
    to,
    focusableRef,
    badge,
    badgeAriaLabel,
  } = props;
  const color = isNamedIconButtonProps(props) ? props.color : undefined;

  const theme = useTheme();
  const classNames = getClassNames({
    size,
    backgroundColor,
    backgroundHoverColor,
    useTransparentBackground,
    borderRadius,
    theme,
    color,
    className,
  });

  const iconBadge = badge ? <div className={classNames.badge}>{badge}</div> : null;

  const buttonAriaLabel = badge ? `${ariaLabel} - ${badgeAriaLabel}` : ariaLabel;

  const icon = isNamedIconButtonProps(props) ? (
    <Icon iconName={props.iconName} className={classNames.icon} />
  ) : (
    <Image description="" source={props.iconSource} />
  );

  if (to) {
    return (
      <InternalLink
        ariaLabel={buttonAriaLabel}
        onClick={onClick}
        className={classNames.iconButton}
        unstyled={true}
        role={role}
        to={to}
        focusableRef={focusableRef}
      >
        {iconBadge}
        {icon}
      </InternalLink>
    );
  }

  return (
    <Clickable
      ariaLabel={buttonAriaLabel}
      onClick={onClick}
      className={classNames.iconButton}
      unstyled={true}
      role={role}
      disabled={disabled}
      focusableRef={focusableRef}
    >
      {iconBadge}
      {icon}
    </Clickable>
  );
};

export default IconButton;
