import {
  canAddPinnedResources,
  canChangeAdminRights,
  canShowNetworkUsers,
  canStartThread,
  findById,
  getAddFavoriteFailureReason,
  getFulfilledAllCompanyGroupId,
  getFulfilledGroupPrivacyStatus,
  getGroupMembershipStatus,
  getStatusForId,
  isAdmin,
  isAllCompanyGroup,
  isDeleted,
  isDynamic,
  isFavorite,
  isInaccessible,
  isJoined,
  isPrivate,
  isThreadstarterRestricted,
} from '@ms/yammer-data/dist/domains/viewerCommunityRelationships/selectors';
import { Group } from '@ms/yammer-data/dist/state/types';

import { useSelector } from '../useSelector';

type BooleanSelector = (groupId: Group['id']) => boolean;

type UseViewerCommunityRelationship = (groupId: Group['id']) => ReturnType<typeof findById>;
export const useViewerCommunityRelationship: UseViewerCommunityRelationship = (groupId) =>
  useSelector((state) => findById(state, groupId));

type UseStatus = (groupId: Group['id']) => ReturnType<typeof getStatusForId>;
export const useStatus: UseStatus = (groupId) => useSelector((state) => getStatusForId(state, groupId));

export const useIsAdmin: BooleanSelector = (groupId) => useSelector((state) => isAdmin(state, groupId));
export const useIsAllCompanyGroup: BooleanSelector = (groupId) =>
  useSelector((state) => isAllCompanyGroup(state, groupId));
export const useIsThreadstarterRestricted: BooleanSelector = (groupId) =>
  useSelector((state) => isThreadstarterRestricted(state, groupId));
export const useCanAddPinnedResources: BooleanSelector = (groupId) =>
  useSelector((state) => canAddPinnedResources(state, groupId));
export const useIsDeleted: BooleanSelector = (groupId) => useSelector((state) => isDeleted(state, groupId));
export const useIsFavorite: BooleanSelector = (groupId) => useSelector((state) => isFavorite(state, groupId));
export const useIsInaccessible: BooleanSelector = (groupId) => useSelector((state) => isInaccessible(state, groupId));
export const useIsJoined: BooleanSelector = (groupId) => useSelector((state) => isJoined(state, groupId));
export const useIsPrivate: BooleanSelector = (groupId) => useSelector((state) => isPrivate(state, groupId));
export const useCanStartThread: BooleanSelector = (groupId) => useSelector((state) => canStartThread(state, groupId));
export const useIsDynamic: BooleanSelector = (groupId) => useSelector((state) => isDynamic(state, groupId));

type UseMembershipStatus = (groupId: Group['id']) => ReturnType<typeof getGroupMembershipStatus>;
export const useMembershipStatus: UseMembershipStatus = (groupId) =>
  useSelector((state) => getGroupMembershipStatus(state, groupId));

type UseAddFavoriteFailureReason = () => ReturnType<typeof getAddFavoriteFailureReason>;
export const useAddFavoriteFailureReason: UseAddFavoriteFailureReason = () => useSelector(getAddFavoriteFailureReason);

type UseFulfilledGroupPrivacyStatus = (groupId?: Group['id']) => ReturnType<typeof getFulfilledGroupPrivacyStatus>;
export const useFulfilledGroupPrivacyStatus: UseFulfilledGroupPrivacyStatus = (groupId) =>
  useSelector((state) => getFulfilledGroupPrivacyStatus(state, groupId));

type UseFulfilledAllCompanyGroupId = () => ReturnType<typeof getFulfilledAllCompanyGroupId>;
export const useFulfilledAllCompanyGroupId: UseFulfilledAllCompanyGroupId = () =>
  useSelector((state) => getFulfilledAllCompanyGroupId(state));

export const useCanChangeAdminRights: BooleanSelector = (groupId) =>
  useSelector((state) => canChangeAdminRights(state, groupId));

export const useCanShowNetworkUsers: BooleanSelector = (groupId) =>
  useSelector((state) => canShowNetworkUsers(state, groupId));
