import { getTreatmentsExtensionForGraphqlBody } from '@ms/yammer-data/dist/api';
import { getClientForwardExperiments } from '@ms/yammer-data/dist/domains/experiments/selectors';
import { Store } from '@ms/yammer-data/dist/state/types';
import { LazyRelayAppWrapper } from '@ms/yammer-relay/dist/environment';
import React, { FC } from 'react';

import { YammerWebState } from '../../../state/types';
import config from '../../config';

interface RelayAppWrapperProps {
  readonly reduxStore: Store<YammerWebState>;
  readonly etag?: string;
}

const RelayAppWrapper: FC<RelayAppWrapperProps> = ({ children, reduxStore, etag }) => {
  const state = reduxStore.getState();
  const experiments = getClientForwardExperiments(state);
  const treatments = getTreatmentsExtensionForGraphqlBody({
    state,
    experiments,
  });
  const relayConfig = {
    authScope: config.adal.resources.yammer,
    host: config.hosts.graphql,
    treatments,
    etag,
  };

  return <LazyRelayAppWrapper {...relayConfig}>{children}</LazyRelayAppWrapper>;
};

export default RelayAppWrapper;
