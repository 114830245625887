export const attachableLinkUrl = '/attachable-link';
export const feedPageUrl = '/feed';
export const storylinesPageUrl = '/storylines';
export const usagePolicyUrl = '/usage-policy';
export const createGroupUrl = '/create-group';
export const userSettingsUrl = '/user-settings';
export const delegationSettingsUrl = '/user-settings/delegates';
export const networkAdminSettingsUrl = '/network-admin';
export const storylineAdminSettingsUrl = '/admin/settings/storyline';
export const storylineAdminNotificationSettingsUrl = '/admin/settings/storyline/notifications';
export const storylineAdminAdvancedSettingsUrl = '/admin/settings/storyline/advanced';
export const m365CopilotAdoptionPageUrl = '/admin/m365-copilot-adoption';
export const rootPageUrl = '/';
