/* eslint-disable @ms/forbid-file-names */
import { CurrentNetworkContext } from '@ms/yammer-data/dist/state/types';

import { basenameForWebApp } from './basename';

export interface GetBaseUrlProps {
  readonly currentNetworkContext?: CurrentNetworkContext;
  readonly host?: string;
}
export type GetBaseUrl = (props?: GetBaseUrlProps) => string;

export const getBaseUrl: GetBaseUrl = (props) => {
  const protocol = window.location.protocol;
  const host = props?.host && window.location.host !== props?.host ? props.host : window.location.host;
  const baseUrl = `${host}${basenameForWebApp}`;

  if (props?.currentNetworkContext && props?.currentNetworkContext?.networkPath) {
    return `${protocol}//${baseUrl}/${props?.currentNetworkContext?.networkPath}`;
  }

  return `${protocol}//${baseUrl}`;
};
