import { MainColumnAriaRole } from '@ms/yammer-web-core-ui/dist/components/AppLayout';
import { createContext } from 'react';

export interface AppContextOptions {
  readonly mainColumnAriaRole: MainColumnAriaRole;
  readonly enableCollapsiblePanelNav: boolean;
  readonly enableCommunityDiscoveryV2: boolean;
  readonly fullScreenModalDialogMarginTop?: number;
  readonly messagePanelMarginTop?: number;
  readonly disableVideoPlayer: boolean;
  readonly disableLiveEventVideoPlayer: boolean;
}

export const defaultOptions: AppContextOptions = {
  mainColumnAriaRole: 'main',
  enableCollapsiblePanelNav: false,
  enableCommunityDiscoveryV2: false,
  fullScreenModalDialogMarginTop: undefined,
  messagePanelMarginTop: undefined,
  disableVideoPlayer: false,
  disableLiveEventVideoPlayer: false,
};

export default createContext(defaultOptions);
