import { useRenderToggleableContent } from '@ms/yammer-web-core-ui/dist/util/useRenderToggleableContent';
import { noop } from 'lodash';
import React, { FC, PropsWithChildren, createContext, useContext } from 'react';

interface PostCreateModalContextProps {
  readonly showContent: () => void;
  readonly hideContent: () => void;
}

const defaultPostCreateModalContext: PostCreateModalContextProps = {
  showContent: noop,
  hideContent: noop,
};

const PostCreateModalContext = createContext<PostCreateModalContextProps>(defaultPostCreateModalContext);
export const useShowPostCreateModal = () => useContext<PostCreateModalContextProps>(PostCreateModalContext).showContent;
export const useHidePostCreateModal = () => useContext<PostCreateModalContextProps>(PostCreateModalContext).hideContent;

export interface CreatePostProviderProps {
  readonly usePostCreateModal: () => ReturnType<typeof useRenderToggleableContent>;
}

export const CreatePostProvider: FC<PropsWithChildren<CreatePostProviderProps>> = ({
  usePostCreateModal,
  children,
}) => {
  const { showContent, hideContent, content } = usePostCreateModal();

  return (
    <PostCreateModalContext.Provider value={{ showContent, hideContent }}>
      {children}
      {content}
    </PostCreateModalContext.Provider>
  );
};
