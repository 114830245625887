import {
  MultiTenantOrganizationClients_multiTenantOrganization as MultiTenantOrganization,
  MultiTenantOrganizationClients_multiTenantOrganization_memberNetworks_edges as NetworkEdge,
} from '@ms/yammer-graphql';

import { MultiTenantOrganizationNetwork } from '../../../state/multiTenantOrganizationNetworks/types';
import { MultiTenantNetworkOrganizationEntity } from '../normalizeMultiTenantOrganization.types';

import { renameNodeInEdges } from './edges';
import { coalesceToUndefined } from './nullable';

export const processMultiTenantOrganization: (
  value: MultiTenantOrganization
) => MultiTenantNetworkOrganizationEntity = (value) => {
  const { edges } = value.memberNetworks;

  const renamedNodeInEdges = edges
    ? renameNodeInEdges(
        edges.map((edge: NetworkEdge) => ({
          node: { ...edge.node, isHubNetwork: edge.isHubNetwork, mtoDisplayName: edge.memberNetworkDisplayName },
        }))
      )
    : [];

  return {
    id: value.hubNetwork.id,
    displayName: coalesceToUndefined(value.displayName),
    networks: renamedNodeInEdges,
  };
};

export const processMemberNetworks: (value: MultiTenantOrganizationNetwork) => MultiTenantOrganizationNetwork = (
  value
) => ({
  id: value.id,
  displayName: value.displayName,
  mtoDisplayName: value.mtoDisplayName ?? value.displayName,
  isHubNetwork: value.isHubNetwork,
  tenantId: value.tenantId,
  permalink: value.permalink,
});
